import React from "react";
import Home from "./home";

const Index = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <Home />
    </div>
  );
};

export default Index;
