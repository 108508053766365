import React from "react";
import LoveHateComponent from "./loveHate";

const LoveHate = () => {
  return (
    <div>
      <LoveHateComponent />
    </div>
  );
};

export default LoveHate;
