import React from "react";
import { FiArrowRight } from "react-icons/fi";

const Button = ({ text = "Read More", arrowIcon = true }) => {
  return (
    <button className="relative flex items-center justify-center px-4 py-2 md:px-6 md:py-3 text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-md shadow-lg transition-all duration-300 ease-in-out overflow-hidden group gap-1 hover:gap-0">
      <span className="relative z-10 transition-transform duration-300 ease-in-out group-hover:translate-x-2 md:group-hover:translate-x-2">
        {text}
      </span>
      {arrowIcon && (
        <FiArrowRight
          className=" text-white transition-transform duration-500 ease-in-out translate-x-0 opacity-100 group-hover:translate-x-4 group-hover:opacity-0"
          size={20}
        />
      )}
    </button>
  );
};

export default Button;
