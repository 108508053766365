import React from "react";

const technologies = [
    { name: 'React JS', img: '/images/react.svg' },
    { name: 'Node JS', img: '/images/nodejs.svg' },
    { name: 'Mongo DB', img: '/images/mongodb.svg' },
    { name: 'Express', img: '/images/express-js.svg' },
    { name: 'PHP', img: '/images/php.svg' },
    { name: 'WordPress', img: '/images/wordpress.svg' },
    { name: 'Flutter', img: '/images/flutter.svg' },
    { name: 'Auth0', img: '/images/auth0.svg' },
    { name: 'AWS', img: '/images/aws.svg' },
];

function TechnologyUsed() {
    const duplicatedTechnologies = [...technologies, ...technologies];

    return (
        <div className="mx-auto mt-12 px-5">
            <h1 className=" text-ft_24 md:text-ft_32 text-center font-bold heading-light animate-pulse">Technology We Used</h1>
            <h2 className=" text-ft_20 md:text-ft_26 text-center font-semibold text-light">Our Bond with Technology Grows Stronger Every Day</h2>
            <h3 className="text-ft_16 md:text-ft_20 text-center font-medium mt-3 text-light">Our work with various technologies empowers us to design solutions that sustain and accelerate business growth</h3>
            <div className="relative w-full max-w-4xl mx-auto overflow-hidden mt-5">
                <div className="flex items-center">
                    <div className="flex animate-marquee">
                        {duplicatedTechnologies.map((tech, index) => (
                            <div
                                key={index}
                                className={`flex-shrink-0 w-[170px] h-[150px] m-2 bg-white rounded-lg ${tech?.name === "Auth0" ? "px-10 py-5" : "p-5"} shadow-lg text-center`}
                            >
                                <img
                                    src={tech.img}
                                    alt={tech.name}
                                    className="w-full h-4/5 rounded-t-lg"
                                />
                                <div className="py-1 font-medium text-ft_16 text-black">{tech.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechnologyUsed;
