import React from "react";
import { FiArrowRight } from "react-icons/fi";

const OurServices = () => {
  const ourServicesComponent = [
    {
      serviceName: "Website Development",
      id: 0,
      description:"Our web development is mainly focused on custom building every aspect of website, scalable solutions that accomoadtes future expansion & adding new features & chat bot & protecting your data. we prioritize performance in our development process ensuring that every website is fast, reliable & responsive.",
      content: "We build custom websites for your business.",
      icons: "/images/programming.png",
      altValue: "Website development",
    },
    {
      serviceName: "App Development",
      id: 1,
      description:
        "Our App development service focuses on building custom application that align with your business goals. Using the latest technologies and industry best practices, we create scalable and secure app solutions that enhance your online presence and user engagement.",
      content: "We build custom apps for your business.",
      icons: "/images/app-development.png",
      altValue: "App development",
    },
    {
      serviceName: "Website Design",
      id: 2,
      description:"We focus on creating design that will bring your brand to life. our design will inspire engage & ultimately more your audience to take actions, our custom design process is collaborative, by making sure you`re involved at every step of the process.",
      content: "We create beautiful and easy-to-use websites.",
      icons: "/images/website-design.png",
      altValue: "Web design",
    },
    {
      serviceName: "E-commerce Solutions",
      id: 3,
      description:"Our e-commerce solutions enable you to set up & manage online business on easy-to-use interface for adding, updating & organizing product, managing inventory & setting pricing. Our solutions are designed for scalibility with reliable payment solutions that support various payment methods. Features includes intuitive navigation, responsive design & personalised recommendation by using AI & Machine Learning to enhance shopping experience & drive sales ",
      content: "We set up online stores for your products.",
      icons: "/images/solution.png",
      altValue: "e-commerce",
    },
    {
      serviceName: "Digital Marketing",
      id: 4,
      description:
        "Our digital marketing solutions are designed to help you reach your target audience effectively. From SEO and PPC campaigns to social media strategies, we optimize your online presence to drive traffic and conversions, ensuring measurable results.",
      content: "We help you reach more customers online.",
      icons: "/images/digital-marketing.png",
      altValue: "Digital marketing",
    },
    {
      serviceName: "Maintenance / After Sales Services",
      id: 5,
      description: "You can focus on your business while we ensure your website remains current and functional.",
      content: "We provide after-sales support and maintenance to keep your website running smoothly.",
      icons: "/images/google-ads.png",
      altValue: "Maintenance services",
    },

    // {
    //   serviceName: "Fb & Insta Marketing",
    //   id: 6,
    //   description:
    //     "Our Facebook and Instagram marketing services focus on promoting your brand across social media platforms. We develop tailored strategies to enhance visibility, engage your audience with compelling content, and drive traffic to your website or online store.",
    //   content: "We promote your business on Facebook and Instagram.",
    //   icons: "/images/social-media.png",
    //   altValue: "Social media marketing",
    // },
  ];

  return (
    <div className="mx-auto px-5 lg:px-5per mt-7 ">
      <h1 className=" text-ft_24 md:text-ft_32 text-center font-bold heading-light animate-pulse">Our Services</h1>
      <h3 className="text-ft_16 md:text-ft_20 text-center font-medium md:w-[70%] mx-auto text-light">
        We offer a comprehensive range of digital solutions designed to help your business thrive in today's competitive
        landscape. From creating stunning websites and powerful apps to managing effective digital marketing campaigns,
        our expert team is dedicated to delivering results that drive growth and success. Explore our services below to
        discover how we can support your business goals.
      </h3>
      <div className="grid grid-cols-1 gap-7 md:grid-cols-2 xl:grid-cols-3 mt-7">
        {ourServicesComponent.map((data) => (
          <div
            key={data.id}
            className="relative bg-services-card w-full h-72 cursor-pointer rounded-xl py-3 px-8 text-center shadow-2xl flex flex-col items-center justify-center transition duration-300 ease-in-out transform hover:scale-105 overflow-hidden custom-hover-effect"
          >
            <div className="size-auto bg-white p-5 rounded-full flex justify-center items-center shadow-lg">
              <img alt={data.altValue} className="size-16 lg:size-16" src={data.icons} />
            </div>
            <div className="text-ft_20 lg:text-ft_26 text-blue-30 font-semibold">{data.serviceName}</div>
            <span className="text-ft_16 lg:text-ft_19 text-grey-5 font-medium">{data.content}</span>
            <div className="absolute top-0 left-0 w-full h-full custom-hover-gradient">
              <span className="absolute flex justify-center items-center px-5 top-1/2 bottom-1/2 text-center text-white font-normal text-ft_16 p-4 rounded-xl transition duration-300 ease-in-out">
                {data.description}
              </span>
            </div>
            <div className="absolute right-4 bottom-4 lg:hidden">
              <FiArrowRight className="text-blue-600" size={20} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
