import React from 'react';

const Circle = ({ size, position, bgColor, animation, content }) => {
    return (
        <div
            className={`relative flex hover:scale-105 transform transition duration-300 ease-in-out justify-center items-center rounded-full ${bgColor} ${size} ${position} ${animation} text-white text-center shadow-lg`}
        >
            <div>
                {content}
            </div>
        </div>
    );
};

const LoveHateCircles = () => {
    return (
        <div className='mt-16'>
            <h1 className="text-ft_24 md:text-ft_32 text-center font-bold heading-light animate-pulse">Your Satisfaction, Our Ultimate Business Goal</h1>
            <div className="flex flex-col lg:flex-row justify-center items-center mt-10 space-y-10 lg:space-y-0">

                {/* Left Circle (Love Theme) */}
                <div className='animate-wobble'>
                    <Circle
                        size="w-80 h-80 sm:w-80 sm:h-80 lg:w-100 lg:h-100"
                        position="lg:-mr-20"
                        bgColor="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%"
                        animation="animate-pulse"
                        content={
                            <>
                                <h1 className='font-extrabold text-ft_24 sm:text-ft_28 lg:text-ft_32 text-green-400 mb-4'>What We Love</h1>
                                <ul className='grid gap-1.5'>
                                    <li>Innovation</li>
                                    <li>Collaboration</li>
                                    <li>Continuous Learning</li>
                                    <li>User-Centric Design</li>
                                    <li>Technology Leadership</li>
                                    <li>Inspire Innovation</li>
                                    <li>Agile Process</li>
                                </ul>
                            </>
                        }
                    />
                </div>

                {/* Middle Circle (Heading) */}
                <div className=' border-8 border-gray-800 rounded-full z-50 animate-wobble'>
                    <div className=' border-2 border-white rounded-full z-50'>
                        <Circle
                            size="w-80 h-80 sm:w-52 sm:h-52 lg:w-64 lg:h-64"
                            position="z-10"
                            bgColor="bg-gray-800"
                            animation="animate-pulse"
                            content={
                                <div>
                                    <p className="font-extrabold text-ft_32 sm:text-ft_36 lg:text-ft_40">7</p>
                                    <p className="font-bold">Things</p>
                                    <p>We Love and Hate at</p>
                                    <p className="font-bold">Eeta Tech</p>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* Right Circle (Hate Theme) */}
                <div className='animate-wobble'>
                    <Circle
                        size="w-80 h-80 sm:w-80 sm:h-80 lg:w-100 lg:h-100"
                        position="lg:-ml-20"
                        bgColor="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
                        animation="animate-pulse"
                        content={
                            <>
                                <h1 className='font-extrabold text-ft_24 sm:text-ft_28 lg:text-ft_32 text-red-500 mb-4'>What We Hate</h1>
                                <ul className='grid gap-1.5'>
                                    <li>Technical Debt</li>
                                    <li>Scope Creep</li>
                                    <li>Unrealistic Deadlines</li>
                                    <li>Communication Gaps</li>
                                    <li>Unplanned Work</li>
                                    <li>Unclear Goals</li>
                                    <li>Bug Fixing Marathons</li>
                                </ul>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default LoveHateCircles;
