// src/components/NavLink.js
import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ to, children, onClick }) => {
  return (
    <Link to={to} className="text-dark font-bold transition-colors duration-300 hover:text-gray-300" onClick={onClick}>
      {children}
    </Link>
  );
};

export default NavLink;
