import React from "react";
import TechnologyUsedComponent from "./technologyUsed";

const TechnologyUsed = () => {
  return (
    <div>
      <TechnologyUsedComponent />
    </div>
  );
};

export default TechnologyUsed;
