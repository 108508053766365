import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pathName } from "../../common/constants";
import Button from "../../common/smallComponents/button";

const About = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div class="star-background">
        <div class="stars">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>

        <div className="h-full md:w-1/2 top-0 left-0 right-0 mx-5 text-center flex flex-col place-content-center py-6 px-4 lg:py-10 lg:pl-16">
          <h3 className="font-bold text-ft_24 md:text-ft_32 heading-dark animate-pulse">About Us</h3>
          <h1 className="font-bold text-ft_18 lg:text-ft_24 mt-4 text-dark">Make Your Website Talk Of The Internet</h1>
          <p className="mt-3 text-ft_16 xl:text-ft_20 text-dark">
            At Eeta tech we transform ideas into impactful digital experience. Our expert team has been dedicated to
            delivering innovative and customised web solutions that elevates brand and drives success Our approach is
            collaborative and client-centric, ensuring that every project is a true reflection of your vision. we are
            committed to delivering high-quality results and outstanding service. Let us help you make a lasting
            impression online.
          </p>
        </div>
        <div className="relative md:w-45per rounded-none md:rounded-40">
          <img className="rounded-none md:rounded-40 md:my-5" src="/images/software-background.jpg" alt="Laptop" />
          {pathname !== pathName.ABOUT_US && (
            <div
              className="absolute right-5 top-5 md:top-auto md:bottom-10 lg:right-5per"
              onClick={() => navigate(pathName.ABOUT_US)}
            >
              <Button />
            </div>
          )}
        </div>
      </div>

      {pathname === pathName.ABOUT_US && (
        <div className="px-5 lg:px-5per mt-7 lg:mt-14 grid grid-cols-1 md:grid-cols-2 gap-7">
          <div className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% rounded-full pt-2 pb-5 px-5 text-center">
            <h1 className="text-ft_24 font-bold heading-dark">Vision</h1>
            <p className=" text-white">
              Empowering businesses to thrive in the digital era through innovative technology solutions and
              transformative strategies.
            </p>
          </div>
          <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full pt-2 pb-5 px-5 text-center">
            <h1 className="text-ft_24 font-bold heading-dark">Mission</h1>
            <p className=" text-white">
              To deliver exceptional value to our clients by leveraging cutting-edge technologies, fostering creativity,
              and ensuring unparalleled customer satisfaction.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
