import React from "react";
import OurServices from "../ourServices/ourServices";
import About from "../about/about";
import { phase } from "../../common/constants";
import TechnologyUsed from "../technologyUsed";
import LoveHate from "../loveHate.js";
import { FiArrowRight } from "react-icons/fi";
import WhyChoose from "../whyChoose";

const Home = () => {
  return (
    <div className="pb-7">
      <div className=" fade-background relative mx-auto h-auto w-full pt-5 pb-7 px-5 md:py-8 shadow-2xl lg:px-5per min-h-[320px]">
        <div className=" flex justify-between">
          <img
            className="hidden md:block md:w-88 lg:w-96 xl:w-auto"
            src="/images/left-home-map-image.svg"
            alt="World Map"
          />
          <img
            className="hidden md:block md:w-88 lg:w-96 xl:w-auto"
            src="/images/right-home-map-image.svg"
            alt="World Map"
          />

          <img className="block w-36 md:hidden" src="/images/left-home-map-mobile.svg" alt="World Map" />
          <img className="block w-36 md:hidden" src="/images/right-home-map-mobile.svg" alt="World Map" />
        </div>
        <div className="px-5 absolute top-0 lg:top-9 left-0 right-0 grid justify-cente gap-16 md:gap-12 lg:gap-12 w-auto max-w-[400px] md:max-w-full md:w-[450px] lg:w-[700px] text-center mx-auto mt-5 md:mt-20 lg:mt-10">
          <h1 className="text-ft_20 md:text-ft_24 lg:text-ft_48 font-semibold heading-dark animate-pulse">
            Your Partner in Custom
            </h1>
            <h1 className="flex flex-row justify-center text-ft_20 md:text-ft_24 lg:text-ft_48 font-semibold -mt-16">
              <h1>
                <div>S</div>
                <div>o</div>
                <div>f</div>
                <div>t</div>
                <div>w</div>
                <div>a</div>
                <div>r</div>
                <div>e</div>
                &nbsp;
              </h1>
              <h1>
                <div>D</div>
                <div>e</div>
                <div>v</div>
                <div>e</div>
                <div>l</div>
                <div>o</div>
                <div>p</div>
                <div>m</div>
                <div>e</div>
                <div>n</div>
                <div>t</div>
              </h1>
            </h1>
         
          <h3 className="text-ft_16 md:text-ft_20 lg:text-ft_32 mt-16 font-medium text-dark animate-pulse">
            Empowering businesses with innovative software solutions tailored for success
          </h3>
        </div>
      </div>

      <About />
      <OurServices />
      
      <div className="px-5 md:px-0 mt-12">
        <h1 className=" text-ft_24 md:text-ft_32 text-center font-bold heading-light animate-pulse">
          End-to-End Process
        </h1>
        <h3 className="text-ft_16 md:text-ft_20 text-center font-medium md:w-[70%] mx-auto text-light">
          Our End-to-End Development Process covers every phase from requirement gathering to deployment, ensuring a
          seamless transition and high-quality software delivery that meets your business needs and supports future
          growth
        </h3>
      </div>
      <div className="flex flex-col-reverse gap-7 md:gap-0 lg:flex-row place-content-center justify-around px-5 lg:px-5per mt-7 lg:mt-14">
        <div className="grid grid-cols-1 gap-2">
          {phase.map((item) => {
            return (
              <div
                key={item.title.toLowerCase()}
                className="relative flex flex-col text-ft_24 font-bold group cursor-default"
              >
                <div className="flex items-center gap-2">
                  <div className="flex justify-center items-center w-12 h-10 md:w-13.5 md:h-12 rounded-full bg-green-200 border border-cyan-900 transition-transform transform group-hover:scale-110 duration-300 text-ft_18 md:text-ft_24 bg-gradient-to-r from-indigo-500">
                    {item.sNo}
                  </div>
                  <div className="flex p-2 gap-5 rounded-r-full shadow-lg border md:max-w-125 border-cyan-900 w-full justify-between text-ft_16 md:text-ft_24 font-extrabold items-center text-light">
                    {item.title}
                    <FiArrowRight className="text-blue-600" size={20} />
                  </div>
                </div>

                <div className="absolute left-0 mt-12 md:mt-14 bg-purple-100 rounded-lg p-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-700 w-auto md:w-96 ml-12 md:ml-14 z-10 shadow-lg text-ft_16 md:text-ft_20 font-medium">
                  {item.description}
                </div>
              </div>
            );
          })}
        </div>

        <img className="h-auto lg:h-80" src="/images/phases.png" alt="Phases" />
      </div>
      <WhyChoose/>
      <LoveHate />
      <TechnologyUsed />
    </div>
  );
};

export default Home;
