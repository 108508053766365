import React from "react";
import WhyChooseComponent from "./whyChoose";

const WhyChoose = () => {
  return (
    <div>
      <WhyChooseComponent />
    </div>
  );
};

export default WhyChoose;
