import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

const WhyChooseUs = () => {
    const sections = [
        {
            title: 'Expertise',
            content: "Our team of seasoned developers brings unparalleled expertise in software development. We deliver innovative, scalable, and robust solutions tailored to your business needs, ensuring that our technical skills and in-depth knowledge drive the success of every project we undertake.",
            image: '/images/expert.png'
        },
        {
            title: 'Transparency and Communication',
            content: 'We believe in fostering open and transparent communication throughout every stage of a project. Our clients are kept fully informed with regular updates and reports, ensuring clarity and trust. This collaborative approach ensures that all expectations are met, with no surprises, leading to a smoother and more efficient development process.',
            image: '/images/conversation.png'
        },
        {
            title: 'Timely Delivery',
            content: 'Meeting deadlines is at the core of our service commitment. Our team is dedicated to delivering projects on time, without compromising on quality. By adhering to well-defined timelines and employing agile methodologies, we ensure that your project progresses smoothly, allowing you to meet market demands and seize opportunities effectively.',
            image: '/images/real-time.png'
        },
        {
            title: 'Affordable Pricing',
            content: 'We offer competitive pricing models without sacrificing the quality of our services. By optimizing resources and streamlining processes, we provide cost-effective solutions that meet your budget requirements. Our flexible pricing plans are designed to offer maximum value, ensuring that you get the best return on your investment.',
            image: '/images/affordable.png'
        },
        {
            title: 'Support and Maintenance',
            content: 'Our commitment to your success doesn’t end with the delivery of your project. We provide comprehensive post-launch support and maintenance services to ensure that your software continues to perform optimally. From regular updates to troubleshooting and enhancements, we are here to assist you in maintaining the long-term viability of your solution.',
            image: '/images/technical-support.png'
        },
        {
            title: 'Quality Assurance',
            content: 'Quality is our top priority. Our rigorous testing processes ensure that every product we deliver is bug-free and meets the highest industry standards. By employing a comprehensive quality assurance strategy, we guarantee that your software is reliable, secure, and performs flawlessly under all conditions, giving you peace of mind and satisfaction.',
            image: '/images/quality-assurance.png'
        },
        {
            title: 'Marketing Support',
            content: 'Beyond development, we help amplify your product’s reach with our marketing support services. From crafting compelling strategies to executing targeted campaigns, we ensure that your software not only functions excellently but also gains visibility in the marketplace. Our marketing expertise helps you attract users, build brand awareness, and drive business growth.',
            image: '/images/service.png'
        },
    ];

    // Custom Arrow Components
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2  rounded-full cursor-pointer z-10" onClick={onClick}>
                <GoChevronRight size={50} className="text-white text-lg" />
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2  rounded-full cursor-pointer z-10" onClick={onClick}>
                <GoChevronLeft size={50} className="text-white text-lg" />
            </div>
        );
    };

    // Slider settings with arrows and customized dots
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dotsClass: "slick-dots slick-thumb",
    };

    return (
        <div className="w-full max-w-4xl mx-auto px-5 md:px-8 pt-8 mt-4 relative">
            <h1 className="text-ft_24 md:text-ft_32 text-center font-bold heading-light animate-pulse">
                Why Choose Us
            </h1>
            <h2 className="text-ft_20 md:text-ft_26 text-center font-semibold text-light">
                Driving Innovation with Proven Excellence in Software Development
            </h2>
            <h3 className="text-ft_16 md:text-ft_20 text-center font-medium mt-3 text-light">
                Partner with us to leverage cutting-edge technologies, expert-driven solutions, and a dedicated team committed to your success.
            </h3>

            <Slider {...settings}>
                {sections.map((section, index) => (
                    <div key={index} className="mt-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white rounded-lg shadow-xl p-4 md:p-8 transition duration-300 transform">
                        <div className='min-h-80 md:min-h-44'>
                            <h2 className="text-ft_24 md:text-3xl font-extrabold mb-2 md:mb-4 text-center">{section.title}</h2>
                            <p className="text-ft_16 md:text-lg mb-4 text-center">{section.content}</p>
                        </div>
                        <img
                            src={section.image}
                            alt={section.title}
                            className="rounded-lg object-cover mx-auto h-56 w-full shadow-md md:w-76 md:h-68 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default WhyChooseUs;
