import React, { useState } from "react";
import { companyInfo, countries } from "../../common/constants";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountrySearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCountrySelect = (country) => {
    setFormData((prevData) => ({
      ...prevData,
      country: country.name,
    }));
    setIsDropdownOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${companyInfo.email}?subject=Contact%20Us&body=Name:%20${formData.name}%0ACompany%20Name:%20${formData.companyName}%0AEmail:%20${formData.email}%0APhone:%20${formData.phone}%0AAddress:%20${formData.address}%0ACountry:%20${formData.country}`;
    window.location.href = mailtoLink;
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-2xl mt-7 p-5 border border-cyan-900 shadow-md rounded-lg mx-5 md:mx-auto">
      <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
      <p className="mb-6 text-gray-600">
        We are a software development company offering a wide range of services. Feel free to reach out to us for any
        inquiries or collaborations.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
            Company Name (Optional)
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
            Country
          </label>
          <div className="relative">
            <button
              type="button"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-full px-3 py-2 border border-cyan-900 rounded bg-transparent focus:outline-none flex justify-between items-center"
            >
              {formData.country || "Select your country"}
              <svg
                className={`w-5 h-5 transform transition-transform duration-200 ${isDropdownOpen ? "rotate-180" : ""}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            {isDropdownOpen && (
              <div className="absolute z-10 w-full mt-2 bg-white border rounded shadow-lg">
                <input
                  type="text"
                  className="w-full px-3 py-2 border-b border-cyan-900 rounded-t focus:outline-none"
                  placeholder="Search for your country"
                  value={searchTerm}
                  onChange={handleCountrySearch}
                />
                <ul className="max-h-60 overflow-y-auto">
                  {filteredCountries.map((country, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleCountrySelect(country)}
                    >
                      {window.innerWidth < 1024 && country.flag} {country.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
