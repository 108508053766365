// src/components/Footer.js
import React from "react";
import NavLink from "./navLink";
import { companyInfo, navLinks, socialLink } from "./constants";
import { FaMapMarkedAlt } from "react-icons/fa";
import { PiPhoneCallFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  const renderCompanyInformation = () => {
    return (
      <ul className="text-dark flex flex-wrap flex-col gap-1.5 md:flex-row md:gap-0">
        <li className="mr-4 flex">
          <FaMapMarkedAlt size={24} className="mr-2 text-white" />
          <span className="text-dark">{companyInfo.address}</span>
        </li>
        <li className="mr-4">
          <a href={`tel:${companyInfo.mobile}`} className="flex hover:text-gray-400 duration-500">
            <PiPhoneCallFill size={24} className="mr-2 text-white" />
            <span className="text-dark">{companyInfo.mobile}</span>
          </a>
        </li>
        <li>
          <a href={`mailto:${companyInfo.email}`} className="hover:text-gray-400 flex duration-500">
            <MdEmail size={24} className="mr-2 text-white" />
            <span className="text-dark">{companyInfo.email}</span>
          </a>
        </li>
      </ul>
    );
  };

  return (
    <footer className="bg-navyBlue-10 py-6 mt-8">
      <div className="px-5 lg:px-5per">
        <div className="md:flex md:justify-between md:items-center">
          <div className="flex gap-2 items-center mb-6 md:mb-0">
            <img src="/images/logo.png" alt="Logo" className="w-12 h-10 rounded-lg shadow-2xl" />
            <span className="text-ft_26 font-medium text-dark">Eeta Tech</span>
          </div>
          <nav className="flex justify-center mb-6 md:mb-0">
            <ul className="flex flex-wrap gap-4">
              {navLinks.map((link) => (
                <li key={link.path}>
                  <NavLink to={link.path} className="text-dark hover:text-gray-400 duration-500">
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:items-center mt-4">
          <div className="text-center md:text-left mb-6 md:mb-0">{renderCompanyInformation()}</div>
          <div className="flex justify-center md:justify-end">
            <ul className="flex items-center gap-4">
              <li className="flex whitespace-nowrap text-dark">Follow Us On:</li>
              <li className="flex">
                <a href={socialLink.facebook} target="_blank" rel="noopener noreferrer">
                  <svg
                    className="w-5 h-5 text-white hover:text-gray-400 duration-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href={socialLink.instagram} target="_blank" rel="noopener noreferrer">
                  <svg
                    className="w-5 h-5 text-white hover:text-gray-400 duration-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              {/* <li>
                <a href={socialLink.twitter} target="_blank" rel="noopener noreferrer">
                  <svg
                    className="w-5 h-5 text-white hover:text-gray-400 duration-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li> */}
              <li>
                <a href={socialLink.linkedin} target="_blank" rel="noopener noreferrer">
                  <svg
                    className="w-4 h-4 text-white hover:text-gray-400 duration-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M22.23 0H1.77C.79 0 0 .77 0 1.74v20.52C0 23.23.79 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.74V1.74C24 .77 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.48a2.07 2.07 0 010-4.14 2.07 2.07 0 010 4.14zm15.11 12.97h-3.56V14.8c0-1.34-.02-3.07-1.87-3.07-1.88 0-2.17 1.46-2.17 2.97v6.75h-3.56V9h3.42v1.56h.05c.48-.91 1.65-1.87 3.39-1.87 3.62 0 4.29 2.38 4.29 5.46v6.3z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-600 mt-4 pt-4">
          <p className="text-center text-dark text-sm">© {new Date().getFullYear()} Eeta Tech. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
