// src/routes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./common/header";
import Home from "./modules/home";
import About from "./modules/about";
import Contact from "./modules/contact";
import { pathName } from "./common/constants";
import "./index.css";
import "./index.scss";
import OurServices from "./modules/ourServices";
import Footer from "./common/footer";

const CustomRoutes = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route exact path={pathName.HOME} element={<Home />} />
        <Route exact path={pathName.SERVICES} element={<OurServices />} />
        <Route path={pathName.ABOUT_US} element={<About />} />
        <Route path={pathName.CONTACT_US} element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default CustomRoutes;
